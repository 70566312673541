import React from 'react';
import { Helmet } from 'react-helmet';
import { SeoMetadata } from '../../lib/sanity-types/seo-metadata';

const Seo = ({ title, description, ogImageUrl, keywords }: SeoMetadata) => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'de' }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ].concat(
        keywords && keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : []
      )}
    />
  );
};

export default Seo;
