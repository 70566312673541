import React from 'react';
import FaIcon from './faIcon';

type FooterSocialmediaItemProps = {
  link: string;
  icon: string;
};

const FooterSocialmediaItem = (props: FooterSocialmediaItemProps) => {
  return (
    <a href={props.link} className="inline-block w-10 mr-2 p-2 rounded cursor-pointer" target="_blank" rel="noopener">
      <FaIcon color={'text-white'} iconName={props.icon} size={'text-lg'}></FaIcon>
    </a>
  );
};

export default FooterSocialmediaItem;
