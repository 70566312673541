import React from 'react';
import 'twin.macro';
import Footer from './footer';
import PropTypes from 'prop-types';
import FooterWave from './footerWave';
import Header from './header';
import { MainImageMetadataProps } from '../../lib/sanity-types/main-image';
import { getGatsbyImage } from '../../lib/helpers';
import { FooterProps } from '../../lib/sanity-types/footer';
import { css } from 'twin.macro';
import { Global } from '@emotion/react';
import { motion, AnimatePresence } from 'framer-motion';
import { SeoMetadata } from '../../lib/sanity-types/seo-metadata';
import { NavigationItemMetadataProps } from '../../lib/sanity-types/navigation-item';

const pathname: string = typeof window !== 'undefined' ? window.location.pathname : '';
const duration = 0.2;
const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const globalStyles = css`
  html {
    font-family: 'UniversLightCondensed';
    font-size: 1rem;
    color: #080049;
    background-color: #f9fafb;
  }
  #tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #00f2ee;
    background: -webkit-radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );
    background: -moz-radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );
    background: radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );

    canvas {
      position: absolute !important;
    }
  }
  .gradient-background {
    background: #00f2ee;
    background: -webkit-radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );
    background: -moz-radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );
    background: radial-gradient(
      circle farthest-side at bottom left,
      #00f2ee 2%,
      #00eae8 12%,
      #01d3d9 22%,
      #02aec0 33%,
      #047b9d 53%,
      #063a70 74%,
      #080049 100%
    );
  }
`;

interface LayoutProps {
  children: any;
  navigationItems: NavigationItemMetadataProps[];
  seoMetadata: SeoMetadata;
  mainImage: MainImageMetadataProps;
  footerProps: FooterProps;
}

const Layout = (props: LayoutProps) => {
  const logo = getGatsbyImage(props.mainImage);

  return (
    <div className='flex flex-col h-screen'>
      <Global styles={globalStyles} />
      <div>
        <Header
          seoMetadata={props.seoMetadata}
          navigationItems={props.navigationItems}
          logo={logo}
        />
      </div>
      <div className='mb-auto'>
        <AnimatePresence>
          <motion.main
            key={pathname}
            variants={variants}
            initial='initial'
            animate='enter'
            exit='exit'>
            {props.children}
          </motion.main>
        </AnimatePresence>
      </div>
      <div className='gradient-background mt-20 lg:mt-32'>
        <FooterWave />
        <Footer {...props.footerProps} />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
