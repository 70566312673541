import React from 'react';
import Rocket from './../../assets/rocket.svg';
import { css } from 'twin.macro';
import { FooterProps } from '../../lib/sanity-types/footer';
import FooterSocialmediaItem from './footerSocialmediaItem';
import { FooterLinkType } from '../../lib/sanity-types/footer-link';
import FooterLink from './footerLink';
import { FooterSocialMediaLink } from '../../lib/sanity-types/footer-socialmedia-link';

const Footer = (props: FooterProps) => {
  return (
    <footer className="w-full py-12 text-white">
      <div className="max-w-screen-xl mx-auto px-4">
        <section>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 mb-16 lg:mb-0">
                <p className="mb-4 max-w-sm leading-loose">{props.footerTagline}</p>
                <div>
                  {props.footerSocialMedia.map((socialMediaItem: FooterSocialMediaLink) => (
                    <FooterSocialmediaItem
                      key={socialMediaItem.id}
                      link={socialMediaItem.link}
                      icon={socialMediaItem.icon}
                    />
                  ))}
                </div>
              </div>
              <div className="w-full lg:w-2/3 lg:pl-16 flex flex-wrap justify-between">
                <div className="w-full md:w-1/3 lg:w-auto mb-16 md:mb-0">
                  <div className="mb-6 text-base font-universBoldCondensed">Produkte</div>
                  <ul>
                    {props.footerProductLinks.map((link: FooterLinkType) => (
                      <FooterLink name={link.navigationMenuName} url={link.url} key={link.id} />
                    ))}
                  </ul>
                </div>
                <div className="w-full md:w-1/3 lg:w-auto mb-16 md:mb-0">
                  <div className="mb-6 text-base font-universBoldCondensed">Links</div>
                  <ul>
                    {props.footerGeneralLinks.map((link: FooterLinkType) => (
                      <FooterLink name={link.navigationMenuName} url={link.url} key={link.id} />
                    ))}
                  </ul>
                </div>
                <div className="w-full md:w-1/3 lg:w-auto">
                  <div className="mb-6 text-base font-universBoldCondensed">Firma</div>
                  <ul>
                    {props.footerCompanyLinks.map((link: FooterLinkType) => (
                      <FooterLink name={link.navigationMenuName} url={link.url} key={link.id} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <p className="lg:text-center text-sm border-t pt-6 mt-12">© 2021 easymining.ch</p>
          </div>
        </section>

        <div className="relative w-full z-10">
          <div
            css={css`
              width: 50px;
              bottom: 875px;
              @media (min-width: 640px) {
                bottom: 850px;
                width: 60px;
              }
              @media (min-width: 768px) {
                bottom: 440px;
                width: 65px;
              }
              @media (min-width: 1024px) {
                bottom: 320px;
                width: 75px;
              }
              @media (min-width: 1536px) {
                bottom: 400px;
                width: 75px;
              }
            `}
            className="absolute right-8 w-full"
          >
            <img
              className="animate-bounce w-36 h-36"
              src={Rocket}
              alt="Bitcoin Rakete - Heben Sie mit EASYMINING.CH ab"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
