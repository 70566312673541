import React from 'react';

type FaIconProps = {
  color: string;
  iconName: string;
  size: string;
  wrapperSpacing?: string;
};

const FaIcon = ({ color, iconName, size, wrapperSpacing }: FaIconProps) => {
  const wrapperClasses = `mx-auto ${wrapperSpacing} ${color}`;
  const iconclasses: string = `${iconName} ${size}`;

  return (
    <div className={wrapperClasses}>
      <i aria-hidden className={iconclasses} title="Social Media Icon Link" />
    </div>
  );
};

export default FaIcon;
