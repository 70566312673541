import React from 'react';
import 'twin.macro';

type MaxWidthContainerProps = { children: any };

function MaxWidthContainer({ children }: MaxWidthContainerProps) {
  return <div tw="container mx-auto">{children}</div>;
}

export default MaxWidthContainer;
