import React from 'react';
import { Link } from 'gatsby';

type FooterLink = {
  name: string;
  url: string;
};

const FooterLink = ({ name, url }: FooterLink) => {
  return (
    <li className="mb-4">
      <Link className="text-white hover:text-yellow-300" to={url}>
        {name}
      </Link>
    </li>
  );
};

export default FooterLink;
