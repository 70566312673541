import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import { Cross as Hamburger } from 'hamburger-react';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import tw, { css, styled } from 'twin.macro';
import { StoreContext } from '../../context/store-context';
import { NavigationItemMetadataProps } from '../../lib/sanity-types/navigation-item';
import { SeoMetadata } from '../../lib/sanity-types/seo-metadata';
import MaxWidthContainer from './maxWidthContainer';
import Seo from './seo';

type HeaderMetadataProps = {
  navigationItems: NavigationItemMetadataProps[];
  seoMetadata: SeoMetadata;
  logo: any;
};

const NavigationBarWrapper = styled.header`
          ${tw`bg-white shadow`}
`;

const NavigationBar = styled.div`
          ${tw`flex flex-row flex-wrap justify-between items-center p-4 md:p-6 text-blue-navy`};
`;

const HambrugerWrapper = styled.div`
          ${tw`flex xl:hidden`}
`;

const NavigationDesktop = styled.nav`
          ${tw`hidden xl:flex relative space-x-4`}
`;

const NavigationMobile = styled.nav`
          ${tw`flex flex-col items-center relative w-full bg-white rounded-b-lg xl:hidden z-10`}
`;

const NavigationLink = styled(Link)`
          &.active {
                    color: #00f2ee;
          }
          ${tw`p-3 text-blue-navy font-universBoldCondensed hover:text-blue-aqua transition delay-100 duration-200 ease-in-out`}
`;

const CartNotEmptyIndicator = () => {
  return (
    <div className='absolute top-0 right-0 mt-3 mr-0.5 w-2 h-2 rounded-full bg-blue-aqua'></div>
  );
};

const CartNavItem = ({ isEmpty, isMobile }: { isEmpty: boolean; isMobile: boolean }) => {
  const wrapperClasses = isMobile ? 'mb-4' : '';

  return (
    <div className={wrapperClasses}>
      {!isEmpty && <CartNotEmptyIndicator></CartNotEmptyIndicator>}
      <div>
        <svg
          className='fill-current w-4'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'>
          <title>Warenkorb</title>
          <path d='M4 2h16l-3 9H4a1 1 0 1 0 0 2h13v2H4a3 3 0 0 1 0-6h.33L3 5 2 2H0V0h3a1 1 0 0 1 1 1v1zm1 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z' />
        </svg>
      </div>
    </div>
  );
};

const Header = ({ navigationItems, seoMetadata, logo }: HeaderMetadataProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { checkout } = useContext(StoreContext);

  const emptyCart = checkout.lineItems.length === 0;

  return (
    <NavigationBarWrapper>
      <Helmet>
        <script
          async
          src='https://kit.fontawesome.com/db021fa57e.js'
          crossOrigin='anonymous'></script>
      </Helmet>
      <Seo
        title={seoMetadata?.title}
        description={seoMetadata?.description}
        ogImageUrl={seoMetadata?.ogImageUrl}
        keywords={seoMetadata?.keywords}></Seo>
      <MaxWidthContainer>
        <NavigationBar>
          <div
            css={css`
                  width: 150px;
                  @media (min-width: 640px) {
                    width: 175px;
                  }`}>
            <Link to='/'>{logo}</Link>
          </div>
          <NavigationDesktop>
            {navigationItems.map(
              (navigationItem: any) => (
                <NavigationLink
                  key={
                    navigationItem.id
                  }
                  to={
                    navigationItem.url
                  }
                  activeClassName='active'
                  partiallyActive>
                  {
                    navigationItem.navigationMenuName
                  }
                </NavigationLink>
              )
            )}
            <NavigationLink
              to='/cart'
              className='flex items-center'>
              <CartNavItem
                isEmpty={emptyCart}
                isMobile={
                  false
                }></CartNavItem>
            </NavigationLink>
          </NavigationDesktop>
          <HambrugerWrapper>
            <Hamburger
              toggled={isOpen}
              size={28}
              toggle={setIsOpen}
              color='#080049'
              rounded
              label='Menu Icon'></Hamburger>
          </HambrugerWrapper>
        </NavigationBar>
      </MaxWidthContainer>

      <Transition
        show={isOpen}
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'>
        {() => (
          <NavigationMobile>
            {navigationItems.map(
              (navigationItem: any) => (
                <NavigationLink
                  key={
                    navigationItem.id
                  }
                  to={
                    navigationItem.url
                  }
                  activeClassName='active'
                  partiallyActive>
                  {
                    navigationItem.navigationMenuName
                  }
                </NavigationLink>
              )
            )}
            <NavigationLink
              to='/cart'
              className='flex items-center relative'>
              <CartNavItem
                isEmpty={emptyCart}
                isMobile={
                  true
                }></CartNavItem>
            </NavigationLink>
          </NavigationMobile>
        )}
      </Transition>
    </NavigationBarWrapper>
  );
};

export default Header;
